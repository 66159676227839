import 'devextreme/dist/css/dx.light.css'
import React, { useEffect, useState } from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import './scss/style.scss'
import 'devextreme/dist/css/dx.light.css'
import { useContext } from 'react'
import { Maincontext } from './context/maincontext'
import EXToast from './reusable/EXToast/EXToast'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
function App() {
  const {isLogedin, toastNotificationList, setToastNotificationList} = useContext(Maincontext)
  const removeToast = (_newlist) => {
    setToastNotificationList(_newlist)
  }  
  return (
    <>
    <EXToast toastList={toastNotificationList} closeToast={removeToast}></EXToast>
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={props => <Login {...props}  />}
          />
          <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
          <Route
            path="/"
            name="Home"
            render={props => (isLogedin  ? <TheLayout {...props} /> : <Redirect to={{ pathname: '/login' }} />)}
          />
        </Switch>
      </React.Suspense>
    </HashRouter>
    </>
  )
}

export default App
