import axios from 'axios'

export const AxiosRequester = async (endPoint, method, authToken, data, LogOut) => {
  axios.defaults.headers.common['X-Amz-ie-Security-Token'] = authToken
  let response = null
  const config = {
    timeout: 1800000 // 3min
  };
  switch (method) {
    case 'post':
      response = await AxiosPostRequest(endPoint, data, config) 
      if(response.status == 401){
        LogOut()
      }
      break
    case 'put':
      response = await AxiosPutRequest(endPoint, data, config)
      if(response.status == 401){
        LogOut()
      }
      break
    case 'get':
      response = await AxiosGetRequest(endPoint, data, config)
      if(response.status == 401){
        LogOut()
      }
      break
    case 'delete':
      response = await AxiosDelRequest(endPoint, data, config)
      if(response.status == 401){
        LogOut()
      }
      break
    default:
      break
  }
  return response
}
const AxiosPostRequest = async (endPoint, data = null, config) => {
  let params = {}
  
  if(data != null){
    params = data
  }
  let response = await axios.post(endPoint, params, config)
  return response
}

const AxiosPutRequest = async (endPoint, data = null, config) => {
  let params = {}
 
  if(data != null){
    params = data
  }
  let response = await axios.put(endPoint, params, config)
  return response
}

const AxiosGetRequest = async (endPoint, data = null, config) => {
  let params = {}
  if(data != null){
    params = data
  }
  let response = await axios.get(endPoint, {params}, config)
  return response
}

const AxiosDelRequest = async (endPoint, data = null, config) => {
  let params = {}
 
  if(data != null){
    params = data
  }
  let response = await axios.delete(endPoint, params, config)
  return response
}
