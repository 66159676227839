import * as React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useCookies } from 'react-cookie'
import { Api_Server_url } from 'src/shared/shared'
import { AxiosRequester } from 'src/utils/AxiosRequester'
export const Maincontext = React.createContext(null)

const userModel = {
  userID: -1,
  userName: '',
  userEmail: '',
  userPhone: '',
  userPicture: null,
  userToken: '',
  lastPath: '',
}

const notificationToastModel = {
  tilte: '',
  description: '',
  background: '',
}

export const MaincontextProvider = ({ children }) => {
  const [cookies, setCookies] = useCookies(['user'])
  const [authToken, setAuthToken] = useState('')

  const [isLogedin, setisLogedin] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  //Change to False
  const [isPending, setIsPending] = useState(false)
  const [currentUser, setCurrentUser] = useState(userModel)
  const [toastNotificationList, setToastNotificationList] = useState([])

  const idNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9]

  const pushNotificationType = ['floating', 'bottom_sheet', '50', '75', '90', 'full_screen']

  const pushNotificationDestination = [
    'campaign_details',
    'product_details',
    'notifications_center',
    'brand_url_launcher',
  ]

  const pushNotificationMessageType = [
    'custom',
    'brand_target',
    'community_target',
    'impact',
    'records',
  ]

  const staticNotificationMessageType = [
    'new_campaign',
    'new_rvm',
    'product_added',
    'ie_birthdate',
    'user_birthdate',
    'monthly_summary',
    'yearly_summary',
  ]

  const transactionsMatch = [
    {
      id: 0,
      title: 'Equal to number of transactions',
    },
    {
      id: 1,
      title: 'Greater than number of transactions',
    },
    {
      id: 2,
      title: 'Less than number of transactions',
    },
  ]

  const pushNotification = (_notification, message = null) => {
    let _new_toast = {
      type: _notification,
      text: message
    }
    setToastNotificationList([...toastNotificationList, _new_toast])
  }

  const setUser = async user => {
    setIsLoading(true)
    setCurrentUser(user)
    setAuthToken(user.userToken)
    setCookies('user', SetToken(user), { path: '*', SameSite: 'None' })
    setTimeout(() => {
      setisLogedin(true)
      setIsLoading(false)
    }, 3000)
  }

  const LogOut = () => {
    setCookies('user', null, { path: '*', SameSite: 'None' })
    setCookies('lastPath', null, { path: '*', SameSite: 'None' })
    setCurrentUser(userModel)
    setIsLoading(false)
    setisLogedin(false)
  }

  const SetToken = user => {
    return (
      user.userID +
      '~' +
      user.userName +
      '~' +
      user.userEmail +
      '~' +
      user.userPhone +
      '~' +
      user.userPicture +
      '~' +
      user.userToken
    )
  }

  const GetToken = userstring => {
    if (userstring) {
      let userString = userstring.split('~')
      let user = {
        userID: userString[0],
        userName: userString[1],
        userEmail: userString[2],
        userPhone: userString[3],
        userPicture: userString[4],
        userToken: userString[5],
      }
      return user
    } else {
      return null
    }
  }

  const checkIsLogin = () => {
    let authToken = cookies.user
    if (!authToken) {
      LogOut()
      return
    }
    if (authToken == '') {
      LogOut()
      return
    }
    if (authToken == 'null') {
      LogOut()
      return
    }
    setIsLoading(true)
    let user = GetToken(authToken)
    setCurrentUser(user)
    setAuthToken(user.userToken)
    setisLogedin(true)
  }

  useEffect(() => {
    checkIsLogin()
    setIsLoading(false)
  }, [])

  useEffect(() => {
  }, [isLoading])
  

  return (
    <Maincontext.Provider
      value={{
        setUser,
        currentUser,
        isLogedin,
        isLoading,
        setIsLoading,
        isPending,
        setIsPending,
        idNumbers,
        pushNotificationType,
        pushNotificationDestination,
        pushNotificationMessageType,
        staticNotificationMessageType,
        transactionsMatch,
        LogOut,
        authToken,
        toastNotificationList,
        pushNotification,
        setToastNotificationList
      }}
    >
      {children}
    </Maincontext.Provider>
  )
}
