import default_avatar from '../assets/pictures/default_avatar.png'
export const SERVER_URL = 'https://rbi.imaginedearth.com:3003/'
export const System_Avatar = default_avatar
export const Google_Api_Key = "AIzaSyDBrsYHWd-cEc95HOqOgmc_ZC3XHmLrZyw"
export const RVM_URL = "https://api.imaginedearth.com/uploads/images/rvms/"
export const EncoderSecretPhrase = "abcdefghijklmnopqrstu";





//Develop
// export const Api_Server_url = 'http://127.0.0.1:3000/'
// export const Test_Notification_url = 'http://test.imaginedearth.com:8082/api/v3/notifications/send_test_notification?notification'
// export const Virtual_Rvm_url = 'http://test.imaginedearth.com:8082/api/tide/vrvm/scanner/store-transaction'
// export const post_Image_url = 'http://test.imaginedearth.com:8082/api/v3/tideimages/image'
// export const get_Image_url = 'http://test.imaginedearth.com:8082/api/v3/tideimages/image' 
// export const special_images_url = 'https://api.imaginedearth.com/uploads/images/'
// export const laravel_api_generate_coupon_url = 'http://localhost:3570/api/v3/coupons/generator/generic'
// export const Tide_Coupon_Provider = "EOyaL8O0gnmazqzxd4pAOuaRVzYLFMXNiY5rBKkzVJ1OmRziD83jJpejylL";


//Production
export const Test_Notification_url = 'https://api.imaginedearth.com/api/v3/notifications/send_test_notification?notification'
export const Virtual_Rvm_url = 'https://api.imaginedearth.com/api/tide/vrvm/scanner/store-transaction'
export const Api_Server_url = 'https://cw9pbohxub.execute-api.af-south-1.amazonaws.com/Prod/' 
export const post_Image_url = 'https://api.imaginedearth.com/api/v3/tideimages/image'
export const get_Image_url = 'https://api.imaginedearth.com/api/v3/tideimages/image' 
export const special_images_url = 'https://api.imaginedearth.com/uploads/images/'
export const laravel_api_generate_coupon_url = 'https://api.imaginedearth.com/api/v3/coupons/generator/generic'
export const Tide_Coupon_Provider = "LFAM01G8CqwYfDS8PV6mr3miavDZlFT7QWApCrH313";

